import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import {
  PLAYER_ROUTE,
  SIGN_UP_ROUTE,
  SIGN_IN_ROUTE,
  RESET_PASSWORD_ROUTE,
  HOME_ROUTE,
  CHANNEL_ROUTE,
  SUPPORT_ROUTE,
  MY_PROFILE,
  ABOUT_ROUTE,
  CREATOR_ROUTE,
} from 'pages/Routes';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AccessMenu from '../Access_Drop_Menu/dropdown_menu'; // import the access menu
import Avatar from '../../assets/icons/Sign_In_Page_Icons/user.png';
import { getAuth } from 'firebase/auth';
import Person from '../../assets/icons/Sign_In_Page_Icons/personicon.png';
import { useLanguage } from 'components/Translate/LanguageContext';
import { apiKey } from '../../../config.js';
import { Translator, Translate } from 'react-auto-translate';
const SUB_DROPDOWN_CLOSED = 'closed';
const SUB_DROPDOWN_OPENED = 'opened';

// Took the settings menu code from the MainNav component and turned it into a component
const SettingsMenu = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const page = window.location.pathname;
  const [subDropdownState, setSubDropdownState] = useState(SUB_DROPDOWN_CLOSED);
  const toggleSubDropdown = () =>
    setSubDropdownState(subDropdownState === SUB_DROPDOWN_CLOSED ? SUB_DROPDOWN_OPENED : SUB_DROPDOWN_CLOSED);
  const { language, changeLanguage } = useLanguage();

  const handleSignOut = async (e) => {
    e.preventDefault();
    localStorage.removeItem('isOver16'); // 在用户注销时移除年龄验证状态
    try {
      await auth.signOut();
      window.location.href = PLAYER_ROUTE;
      ROUTE;
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const handleLanguageChange = (e) => {
    changeLanguage(e.target.value);
  };

  // Closes menu after clicking anywhere outside of the menu
  useEffect(() => {
    let subDropdownHandler = (e) => {
      // Refers to the user icon to open the menu
      const dropdownMenu = document.querySelector('.dropdown-user');

      if (dropdownMenu) {
        // Prevents "Cannot read properties of null" error when the gn-main_nav-account class isn't rendered on the page
        // First condition checks that no children elements within the menu class are being targeted
        // Second condition added since it prevents the language popup menu from iniitally rendering in place then moving to the left side of the screen
        if (!dropdownMenu.contains(e.target) && document.body.style.overflow !== 'hidden') {
          setSubDropdownState(SUB_DROPDOWN_CLOSED);
        }
      }
    };

    document.body.addEventListener('click', subDropdownHandler);

    return () => {
      document.body.removeEventListener('click', subDropdownHandler);
    };
  });

  return (
    <Translator from='en' to={language} googleApiKey={apiKey}>
      <div data-state={subDropdownState} className='dropdown-user'>
        {' '}
        {page === PLAYER_ROUTE ? (
          <img
            src={user ? user.photoURL || localStorage.getItem('currentProfilePic') || Avatar : Avatar}
            alt={'Profile Picture'}
            onClick={toggleSubDropdown}
          />
        ) : (
          <img
            src={user ? user.photoURL || localStorage.getItem('currentProfilePic') || Person : Person}
            alt='Profile Picture'
            onClick={toggleSubDropdown}
            width={30}
            height={30}
            style={{ borderRadius: '50%' }}
          />
        )}
        <div data-state={subDropdownState} className='dropdown-user-dropdown-menu'>
          {user &&
            (page === PLAYER_ROUTE ? (
              <a href={MY_PROFILE}>
                <button>
                  <AvatarIcon />
                  <span>
                    <Translate>Profile</Translate>
                  </span>
                </button>
              </a>
            ) : (
              <a href={PLAYER_ROUTE}>
                <button>
                  <PlayerIcon />
                  <span>
                    <Translate>Player</Translate>
                  </span>
                </button>
              </a>
            ))}

          <a href={SUPPORT_ROUTE}>
            <button>
              <HeartHandshakeIcon />
              <span>
                <Translate>Support</Translate>
              </span>
            </button>
          </a>

          <span className='select-nav'>
            <LanguageIcon />
            <FormControl size='small' id='form-control'>
              <Select
                labelId='demo-simple-select'
                id='demo-simple-select'
                value={language}
                label='Language'
                onChange={handleLanguageChange}
                sx={{ color: 'text.primary', bgcolor: 'text.primary' }}
              >
                <MenuItem value='en'>English</MenuItem>
                <MenuItem value='ar'>العربية</MenuItem>
                <MenuItem value='zh'>中文 (简体)</MenuItem>
                <MenuItem value='fr'>Français</MenuItem>
                <MenuItem value='de'>Deutsch</MenuItem>
                <MenuItem value='el'>Ελληνικά</MenuItem>
                <MenuItem value='ht'>Kreyòl Ayisyen</MenuItem>
                <MenuItem value='id'>Bahasa Indonesia</MenuItem>
                <MenuItem value='it'>Italiano</MenuItem>
                <MenuItem value='ja'>日本語</MenuItem>
                <MenuItem value='ko'>한국어</MenuItem>
                <MenuItem value='pt'>Português</MenuItem>
                <MenuItem value='ru'>Русский</MenuItem>
                <MenuItem value='es'>Español</MenuItem>
                <MenuItem value='ur'>اردو</MenuItem>
              </Select>
            </FormControl>
          </span>

          {/*Code for accessability menu*/}

          <AccessMenu />

          <div className='gn-main_nav-divider'></div>

          {user ? (
            <a>
              <button onClick={handleSignOut}>
                <SignOutIcon />
                <span>
                  <Translate>Sign Out</Translate>
                </span>
              </button>
            </a>
          ) : (
            <a href={SIGN_IN_ROUTE}>
              <button>
                <SignInIcon />
                <Translate>Sign In</Translate>
              </button>
            </a>
          )}
        </div>
      </div>
    </Translator>
  );
};

export default SettingsMenu;

const HeartHandshakeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z' />
      <path d='M12 5 9.04 7.96a2.17 2.17 0 0 0 0 3.08v0c.82.82 2.13.85 3 .07l2.07-1.9a2.82 2.82 0 0 1 3.79 0l2.96 2.66' />
      <path d='m18 15-2-2' />
      <path d='m15 18-2-2' />
    </svg>
  );
};

const AvatarIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M18 20a6 6 0 0 0-12 0' />
      <circle cx='12' cy='10' r='4' />
      <circle cx='12' cy='12' r='10' />
    </svg>
  );
};

const SignOutIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4' />
      <polyline points='16 17 21 12 16 7' />
      <line x1='21' x2='9' y1='12' y2='12' />
    </svg>
  );
};

const SignInIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4' />
      <polyline points='10 17 15 12 10 7' />
      <line x1='15' x2='3' y1='12' y2='12' />
    </svg>
  );
};

const PlayerIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <rect width='18' height='18' x='3' y='3' rx='2' />
      <path d='m9 8 6 4-6 4Z' />
    </svg>
  );
};

const LanguageIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='m5 8 6 6' />
      <path d='m4 14 6-6 2-3' />
      <path d='M2 5h12' />
      <path d='M7 2h1' />
      <path d='m22 22-5-10-5 10' />
      <path d='M14 18h6' />
    </svg>
  );
};
