import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import {
  PLAYER_ROUTE,
  SIGN_UP_ROUTE,
  SIGN_IN_ROUTE,
  RESET_PASSWORD_ROUTE,
  HOME_ROUTE,
  CHANNEL_ROUTE,
  SUPPORT_ROUTE,
  CREATOR_ROUTE,
  ABOUT_ROUTE,
} from 'pages/Routes';
import GEAUX_LOGO from '../../assets/icons/Sign_In_Page_Icons/geauxlogo.png';
import { getAuth } from 'firebase/auth';
import { Translator, Translate } from 'react-auto-translate';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AccessMenu from '../Access_Drop_Menu/dropdown_menu'; // import the access menu
import { useLanguage } from 'components/Translate/LanguageContext';
import Person from '../../assets/icons/Sign_In_Page_Icons/personicon.png';
import Bell from '../../assets/icons/Sign_In_Page_Icons/whitebellicon.png';
import ENGLISH from '../../assets/icons/Language_Icons/USA.png';
import SPANISH from '../../assets/icons/Language_Icons/Spain.png';
import KOREAN from '../../assets/icons/Language_Icons/South_Korea.png';
import ARABIC from '../../assets/icons/Language_Icons/Saudi_Arabia.png';
import RUSSIAN from '../../assets/icons/Language_Icons/Russia.png';
import PORTUGUESE from '../../assets/icons/Language_Icons/Portugal.png';
import URDU from '../../assets/icons/Language_Icons/Pakistan.png';
import JAPANESE from '../../assets/icons/Language_Icons/Japan.png';
import ITALIAN from '../../assets/icons/Language_Icons/Italy.png';
import INDONESIAN from '../../assets/icons/Language_Icons/Indonesia.png';
import HAITIAN_CREOLE from '../../assets/icons/Language_Icons/Haiti.png';
import GREEK from '../../assets/icons/Language_Icons/Greece.png';
import GERMAN from '../../assets/icons/Language_Icons/Germany.png';
import FRENCH from '../../assets/icons/Language_Icons/France.png';
import CHINESE from '../../assets/icons/Language_Icons/China.png';
import { apiKey } from '../../../config.js';
import HamburgerMenu from 'components/Hamburger_Menu';
import LanguageMenu from 'components/LanguageMenu';
import './styles.scss';

const OPENED = 'opened';
const CLOSED = 'closed';

// Navbar that's used when on sign up, forgot password, and reset password screens. Contains a language menu.
const SignupNavbar = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const page = window.location.pathname;

  // Toggling the dropdown
  const [dropdownState, setDropdownState] = useState(CLOSED);
  const toggleDropdown = () => setDropdownState((prev) => (prev === CLOSED ? OPENED : CLOSED));

  const handleSignOut = async (e) => {
    e.preventDefault();
    localStorage.removeItem('isOver16'); // 在用户注销时移除年龄验证状态
    try {
      await auth.signOut();
      window.location.href = PLAYER_ROUTE;
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const isSignIn = false; // Passed to HamburgerMenu component to determine what menu options to render

  const { language, changeLanguage } = useLanguage();
  const handleLanguageChange = (e) => {
    changeLanguage(e.target.value);
  };
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 750);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 750);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Closes menu after clicking anywhere outside of the menu
  useEffect(() => {
    let dropdownHandler = (e) => {
      // Refers to the user icon to open the menu
      const menu = document.querySelector('.gn-main_navi-user');

      if (menu) {
        // Prevents "Cannot read properties of null" error when the gn-main_nav-account class isn't rendered on the page
        // First condition checks that no children elements within the menu class are being targeted
        // Second condition added since it prevents the language popup menu from iniitally rendering in place then moving to the left side of the screen
        if (!menu.contains(e.target) && document.body.style.overflow !== 'hidden') {
          setDropdownState(CLOSED);
        }
      }
    };

    document.body.addEventListener('click', dropdownHandler);

    return () => {
      document.body.removeEventListener('click', dropdownHandler);
    };
  }, []);

  return (
    <Translator from='en' to={language} googleApiKey={apiKey}>
      <div className='gn-main_navi'>
        {/* Logo Section */}
        <div>
          <a href={HOME_ROUTE}>
            <img className='gn-main_navi-logo' src={GEAUX_LOGO} alt='Geaux Network Logo' />
          </a>
        </div>

        {/* Navigation Links */}
        <div className='gn-main_anchors'>
          <a href={HOME_ROUTE} className='gn-main_anchor'>
            <Translate>Home</Translate>
          </a>
          <a href={PLAYER_ROUTE} className='gn-main_anchor'>
            <Translate>Channels</Translate>
          </a>
          <a href={CREATOR_ROUTE} className='gn-main_anchor'>
            <Translate>For Creators</Translate>
          </a>
          <a href={ABOUT_ROUTE} className='gn-main_anchor'>
            <Translate>About Us</Translate>
          </a>
        </div>

        <div className='gn-main_icons'>
          <img className='gn-main_navi-bell' src={Bell} alt='Geaux Network Logo' />
          {/* {<img className='gn-main_navi-user' src={Person} alt='Geaux Network Logo' onClick={toggleDropdown} />} */}
          {
            <div data-state={dropdownState} className='gn-main_navi-user' /*ref={dropdownRef*/>
              {' '}
              {/**Commented out till sign in reference error is fixed */}
              {page === PLAYER_ROUTE ? (
                <img
                  src={user ? user.photoURL || localStorage.getItem('currentProfilePic') || Avatar : Avatar}
                  alt={'Profile Picture'}
                  onClick={toggleDropdown}
                />
              ) : (
                <img
                  src={user ? user.photoURL || localStorage.getItem('currentProfilePic') || Person : Person}
                  alt='Profile Picture'
                  onClick={toggleDropdown}
                />
              )}
              <div data-state={dropdownState} className='gn-main_navi-user-dropdown-menu'>
                {user &&
                  (page === PLAYER_ROUTE ? (
                    <a href={MY_PROFILE}>
                      <button>
                        <AvatarIcon />
                        <span>
                          <Translate>Profile</Translate>
                        </span>
                      </button>
                    </a>
                  ) : (
                    <a href={PLAYER_ROUTE}>
                      <button>
                        <PlayerIcon />
                        <span>
                          <Translate>Player</Translate>
                        </span>
                      </button>
                    </a>
                  ))}

                <a href={SUPPORT_ROUTE}>
                  <button>
                    <HeartHandshakeIcon />
                    <span>
                      <Translate>Support</Translate>
                    </span>
                  </button>
                </a>

                {/* Language Selector */}
                <span className='select-nav'>
                  <LanguageIcon />
                  <FormControl size='small' id='form-control'>
                    <Select
                      labelId='demo-simple-select'
                      id='demo-simple-select'
                      value={language}
                      label='Language'
                      onChange={(e) => changeLanguage(e.target.value)}
                      sx={{ color: 'text.primary', bgcolor: 'text.primary' }}
                    >
                      <MenuItem value='en'>English</MenuItem>
                      <MenuItem value='ar'>العربية</MenuItem>
                      <MenuItem value='zh'>中文 (简体)</MenuItem>
                      <MenuItem value='fr'>Français</MenuItem>
                      <MenuItem value='de'>Deutsch</MenuItem>
                      <MenuItem value='el'>Ελληνικά</MenuItem>
                      <MenuItem value='ht'>Kreyòl Ayisyen</MenuItem>
                      <MenuItem value='id'>Bahasa Indonesia</MenuItem>
                      <MenuItem value='it'>Italiano</MenuItem>
                      <MenuItem value='ja'>日本語</MenuItem>
                      <MenuItem value='ko'>한국어</MenuItem>
                      <MenuItem value='pt'>Português</MenuItem>
                      <MenuItem value='ru'>Русский</MenuItem>
                      <MenuItem value='es'>Español</MenuItem>
                      <MenuItem value='ur'>اردو</MenuItem>
                    </Select>
                  </FormControl>
                </span>

                {/* Accessibility Menu */}
                <AccessMenu />

                <div className='gn-main_nav-divider'></div>

                {user ? (
                  <a>
                    <button onClick={handleSignOut}>
                      <SignOutIcon />
                      <span>
                        <Translate>Sign Out</Translate>
                      </span>
                    </button>
                  </a>
                ) : (
                  <a href={SIGN_IN_ROUTE}>
                    <button>
                      <SignInIcon />
                      <Translate>Sign In</Translate>
                    </button>
                  </a>
                )}
              </div>
            </div>
          }
        </div>

        {!isWideScreen ? (
          <HamburgerMenu isSignIn={isSignIn}>
            <SettingsMenu />
          </HamburgerMenu>
        ) : (
          <></>
        )}
      </div>
    </Translator>
  );
};

export default SignupNavbar;

const HeartHandshakeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z' />
      <path d='M12 5 9.04 7.96a2.17 2.17 0 0 0 0 3.08v0c.82.82 2.13.85 3 .07l2.07-1.9a2.82 2.82 0 0 1 3.79 0l2.96 2.66' />
      <path d='m18 15-2-2' />
      <path d='m15 18-2-2' />
    </svg>
  );
};

const AvatarIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M18 20a6 6 0 0 0-12 0' />
      <circle cx='12' cy='10' r='4' />
      <circle cx='12' cy='12' r='10' />
    </svg>
  );
};

const SignOutIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4' />
      <polyline points='16 17 21 12 16 7' />
      <line x1='21' x2='9' y1='12' y2='12' />
    </svg>
  );
};

const SignInIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4' />
      <polyline points='10 17 15 12 10 7' />
      <line x1='15' x2='3' y1='12' y2='12' />
    </svg>
  );
};

const PlayerIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <rect width='18' height='18' x='3' y='3' rx='2' />
      <path d='m9 8 6 4-6 4Z' />
    </svg>
  );
};

const LanguageIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='m5 8 6 6' />
      <path d='m4 14 6-6 2-3' />
      <path d='M2 5h12' />
      <path d='M7 2h1' />
      <path d='m22 22-5-10-5 10' />
      <path d='M14 18h6' />
    </svg>
  );
};
