import React, { useRef, useState, useEffect } from 'react';
import { getVideoId } from 'services/channelInfo';
import { findDOMNode } from 'react-dom';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import { Button, Snackbar, IconButton } from '@material-ui/core';
import { classMap } from 'util/classMap';
import { If } from '../If';
import { Fullscreen, VolumeUp, Close } from '@material-ui/icons';
import PlayerRow from './Row';
import { productData } from './productData';
import { connect } from 'react-redux';
import './styles.scss';
import ChannelInfoOverlay from 'components/ChannelInfoOverlay';

const mapStateProps = (state) => ({
  currentVideo: getVideoId(state),
});

const Player = ({ currentVideo, addProductClick, setUserProduct, userId }) => {
  const playerRef = useRef(null);
  const inactivityTimeoutRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showFullscreenNotification, setShowFullscreenNotification] = useState(false);

  const getAutoplayUrl = (videoUrl) => {
    if (videoUrl.includes('youtube.com')) {
      return videoUrl + '&autoplay=1';
    } else if (videoUrl.includes('vimeo.com')) {
      return videoUrl + '?autoplay=1';
    } else if (
      videoUrl.includes('https://www.wim.tv/') ||
      videoUrl.includes('https://play.streamingvideoprovider.com/')
    ) {
      return videoUrl + '&autoplay=true';
    }

    return videoUrl;
  };

  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      try {
        screenfull.request(findDOMNode(playerRef.current));
        setIsFullscreen(true);
        setShowFullscreenNotification(false); // Hide the notification
      } catch (error) {
        console.error('Error entering fullscreen mode:', error);
      }
    }
  };

  const handleCloseFullscreenNotification = () => {
    setShowFullscreenNotification(false);
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      if (
        screen.orientation &&
        (screen.orientation.type.includes('landscape') || screen.orientation.type.includes('portrait'))
      ) {
        if (screen.orientation.type.includes('landscape')) {
          // Landscape mode
          handleClickFullscreen();
        } else {
          // Portrait mode
          if (isFullscreen) {
            // Exit fullscreen when switching to portrait
            try {
              screenfull.exit();
              setIsFullscreen(false);
            } catch (error) {
              console.error('Error exiting fullscreen mode:', error);
            }
          }
        }
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [isFullscreen]);

  return (
    <div className='gn-player'>
      <ChannelInfoOverlay />

      <If
        test={
          currentVideo.includes('https://www.wim.tv/') ||
          currentVideo.includes('https://play.streamingvideoprovider.com/')
        }
      >
        <div className='gn-player-video-iframe'>
          <iframe
            src={getAutoplayUrl(currentVideo)}
            allow='autoplay'
            allowFullScreen='allowFullScreen'
            ref={playerRef}
            autoPlay
            gesture='media'
            webkitallowfullscreen='true'
            mozallowfullscreen='true'
            msallowfullscreen='true'
          />
        </div>
      </If>
    </div>
  );
};

export default connect(mapStateProps)(Player);
