import './styles.scss';
import React, { useState, useEffect, createContext, useContext } from 'react';
import { If } from '../../components/If';
import { connect } from 'react-redux';
import { collection, doc, getDocs, updateDoc, update, query, where } from 'firebase/firestore';
import {
  getAuth,
  updateProfile,
  updateEmail,
  sendPasswordResetEmail,
  RecaptchaVerifier,
  PhoneAuthProvider,
  updatePhoneNumber,
  updateBirthday,
} from 'firebase/auth';
import avatar from '../../assets/icons/Sign_In_Page_Icons/user.png';
import { db, auth } from '../../firebase-config';
import { classMap } from '../../util/classMap';
import { setUser } from '../../services/userProfile';
import { Redirect, Link } from 'react-router-dom';
import { useFormik, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikField } from '../../components/FormikField';
import { UserContext, useUser } from '../../hooks/use-user';
import { MainNav } from '../../components/MainNav';
import ForgotPassword from '../ForgotPassword';
import { storage } from '../../firebase-config';
import { listAll, ref, getDownloadURL } from 'firebase/storage';
import { Translator, Translate } from 'react-auto-translate';
import { apiKey } from '../../../config.js';
import { FontContext } from '../../context/fontSizeContext'; // code change ----------------import font context-------------------------------------------------------------
import { useLanguage } from 'components/Translate/LanguageContext';

const mapDispatchProps = {
  setUser,
};

const UserProfile = (props) => {
  const userState = useUser(); //only firebase auth is saved to context
  const [user, setUser] = useState({ name: null, email: null, phone: null, photoURL: '', birthday: '1900/01/01' });
  const auth = getAuth(); //full auth
  const message = document.querySelector('#message');

  const [imageList, setImageList] = useState([]);
  const [selectedProfilePic, setSelectedProfilePic] = useState(null);
  const [currentProfilePic, setCurrentProfilePic] = useState({ currentProfilePic: '' });
  const imageListRef = ref(storage, 'AvatarIcon/');
  const { language } = useLanguage();

  function clearMessage() {
    message.innerHTML = '';
  }
  //get the current user
  const getCurrentUser = async () => {
    // console.log('getCurrentUser');
    // console.log(auth.currentUser)
    const q = query(collection(db, 'users'), where('email', '==', auth.currentUser.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      //doc.data() is never undefined for query doc snapshot -- from firestore documentation
      let userData = doc.data();
      let curPhotoURL = userData.photoURL;
      if (curPhotoURL == null) {
        if (auth.currentUser.photoURL == null) {
          //if auth profile pic is null
          auth.currentUser.photoURL = avatar; //set it to default avatar
        }
      }
      curPhotoURL = auth.currentUser.photoURL; //set curPhotoURL to the auth/currentUserPhotoURL
      setUser({
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        photoURL: curPhotoURL,
        birthday: userData.birthday || '',
      });
      console.log('Retrieved user data:', auth.currentUser);
    });
  };

  // Updates the users name
  const changeName = async (newName) => {
    let oldName = user.name;

    if (oldName == newName) {
      return;
    }
    const q = query(collection(db, 'users'), where('email', '==', auth.currentUser.email)); //get user based on current auth email, could add check to see if email auth matches database auth later on
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docu) => {
      //there should only be one, but this loop works fine
      //doc.data() is never undefined for query doc snapshot -- from firestore documentation
      const userRef = doc(db, 'users', docu.id);
      let userData = docu.data();
      let newUserData = { ...userData, name: newName };
      try {
        updateDoc(userRef, newUserData);
      } catch (e) {
        console.log(e);
      }
    });

    setUser({ ...user, name: newName });
    updateProfile(auth.currentUser, {
      displayName: newName,
    })
      .then(() => {
        message.innerHTML = `Name Changed To ${newName}`;
        setTimeout(clearMessage, 2000);
      })
      .catch((error) => {
        console.log('error changing name');
        console.log(error);
      });
  };

  // Updates the users email
  // This function is a bit more difficult with needing to update both the current auth email and the database email,
  // Left as is for now
  const changeEmail = async (newEmail) => {
    let oldEmail = user.email;

    if (oldEmail == newEmail) {
      return;
    }

    setUser({ ...user, email: newEmail });

    updateEmail(auth.currentUser, newEmail)
      .then(() => {
        message.innerHTML = `E-Mail Address Changed To ${newEmail}`;
        setTimeout(clearMessage, 2000);
      })
      .catch((error) => {
        console.log('error changing email');
        console.log(error);
      });
  };

  const updateBirthday = async (user, newBirthday) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { birthday: newBirthday });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const changeBirthday = async (newBirthday) => {
    let oldBirthday = user.birthday;

    if (!newBirthday) {
      return;
    }

    if (oldBirthday === newBirthday) {
      setMessage('');
      setbirthdayUpdate('Birthday already associated with this account');
      setTimeout(() => setbirthdayUpdate(''), 5000);
      return;
    }

    const q = query(collection(db, 'users'), where('email', '==', auth.currentUser.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docu) => {
      const userRef = doc(db, 'users', docu.id);
      let userData = docu.data();
      let newBirthdayData = { ...userData, birthday: newBirthday };
      updateDoc(userRef, newBirthdayData)
        .then(() => {
          setUser((prevUser) => ({ ...prevUser, birthday: newBirthday }));
          setbirthdayUpdate('Birthday Updated');
          setTimeout(() => setbirthdayUpdate(''), 5000);
          setMessage('');
        })
        .catch((error) => {
          console.log(e);
          if (error.code === 'auth/requires-recent-login') {
            setMessage('Session expired please login again');
            setTimeout(clearMessage, 5000);
          } else {
            setMessage('Error changing birthday, please try again');
            setTimeout(clearMessage, 5000);
          }
        });
    });
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  // Phone case variable
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  //Formik validation for name, email, and phone number. Initial values are the current values of the user
  const formik = user
    ? useFormik({
        enableReinitialize: true, //This line is important in terms of using useState to fill out initial values
        initialValues: {
          name: user.name == null ? '' : user.name,
          email: user.email == null ? '' : user.email,
          phoneNumber: user.phone == null ? '' : user.phone.substring(2),
        },
        validationSchema: Yup.object({
          name: Yup.string().max(15, 'Must be 15 characters or less'),
          email: Yup.string().email('Invalid E-Mail'),
          phoneNumber: Yup.string().matches(phoneRegExp, 'Invalid Phone Number').min(10, 'Must Include Area Code'),
        }),
        onSubmit: (values) => {
          const testNum = null;
          if (user.phoneNumber != null) {
            const testNum = user.phoneNumber.substring(2);
          }

          if (user.displayName == values.name && user.email == values.email && testNum == values.phoneNumber) {
            message.innerHTML = 'No Changes Detected';
            setTimeout(clearMessage, 2000);
          }
          changeName(values.name);
          changeEmail(values.email);
          requestOTP(values.phoneNumber);
          changeBirthday(values.birthday);
        },
      })
    : null;

  // Country code is set at US, can be updated later on to be whatever code the user's country holds
  const countryCode = '+1';

  const [expandForm, setExpandForm] = useState(false);
  const [number, setPhoneNumber] = useState('');
  const [OTP, setOTP] = useState('');

  /*
        Generates a recaptcha in its respected window
    */
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha',
      {
        size: 'invisible',
        callback: (response) => {},
      },
      auth
    );
  };

  /*
        Gets the verification code from the user by requesting the phone number
        and sending the text with Recaptcha and PhoneAuth from Firebase
    */
  const requestOTP = (phoneNumber) => {
    phoneNumber = countryCode + phoneNumber;
    if (phoneNumber === user.phoneNumber) {
      return;
    }
    setPhoneNumber(phoneNumber);
    if (phoneNumber.length >= 11) {
      setExpandForm(true);
      message.innerHTML = 'Please Enter the One-Time Passcode Sent To Your Mobile Device';
      generateRecaptcha();
      const provider = new PhoneAuthProvider(auth);
      let appVerifier = window.recaptchaVerifier;
      provider
        .verifyPhoneNumber(phoneNumber, appVerifier)
        .then(function (verificationId) {
          window.verificationId = verificationId;
        })
        .catch((error) => {
          console.log(error);
          if (error.includes('too-many-requests')) {
            message.innerHTML = 'Error: Too Many Requests';
            setTimeout(clearMessage, 2000);
          }
        });
    }
  };

  /*
        Verifies the message input by the user. On correct entry, phone number
        is automatically updated and a message is shown to the user.
    */
  const verifyOTP = (e) => {
    let otp = e.target.value;
    setOTP(otp);

    if (otp.length === 6) {
      console.log(otp);
      let verificationId = window.verificationId;
      let phoneCredential = PhoneAuthProvider.credential(verificationId, otp);
      updatePhoneNumber(user, phoneCredential);
      message.innerHTML = `Phone Number Has Been Updated To ${number}`;
      setExpandForm(false);
      setTimeout(clearMessage, 2000);
    }
  };

  // Retreives all icons from firebase storage as a list with its associated url
  useEffect(() => {
    listAll(imageListRef).then((res) => {
      res.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  //get current user from database on pageload
  useEffect(() => {
    getCurrentUser();
  }, []);

  //set user on userUpdate
  useEffect(() => {
    //console.log("user", user)
    setCurrentProfilePic(user.photoURL); //possibly in the future just remove variable currentProfilePic, everything very tangled
  }, [user]);

  // Updates the user's profile picture in firebase's authentication service
  const handleProfilePicChange = async (url) => {
    if (url === currentProfilePic) return;

    const q = query(collection(db, 'users'), where('email', '==', auth.currentUser.email)); //get user based on current auth email, could add check to see if email auth matches database auth later on
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docu) => {
      //there should only be one, but this loop works fine
      //doc.data() is never undefined for query doc snapshot -- from firestore documentation
      const userRef = doc(db, 'users', docu.id);
      let userData = docu.data();
      console.log('userData', userData);
      let newUserData = { ...userData, photoURL: url };
      console.log('newUserData', newUserData);

      try {
        updateDoc(userRef, newUserData);
      } catch (e) {
        console.log(e);
      }
    });
    updateProfile(auth.currentUser, { photoURL: url })
      .then(() => {
        console.log('Profile picture updated successfully!');
        setCurrentProfilePic(url);
      })
      .catch((error) => {
        console.log('Error updating profile picture: ', error);
      });
  };

  const handleIconClick = (url) => {
    setSelectedProfilePic(url);
  };

  if (userState.user) {
    return (
      <Translator from='en' to={language} googleApiKey={apiKey}>
        <MainNav currentProfilePic={currentProfilePic} />
        <h2>
          <Translate>My Profile</Translate>
        </h2>
        <form className='profile-form' onSubmit={formik.handleSubmit}>
          <div className='section-div'>
            <label htmlFor='name'>
              {' '}
              <Translate>Name:</Translate>
            </label>
            <input className='form-field' {...formik.getFieldProps('name')} />
            {formik.touched.name && formik.errors.name ? <div className='error'>{formik.errors.name}</div> : null}
          </div>
          <div className='section-div'>
            <label htmlFor='email'>
              {' '}
              <Translate>Email:</Translate>
            </label>
            <input className='form-field' {...formik.getFieldProps('email')} />
            {formik.touched.email && formik.errors.email ? <div className='error'>{formik.errors.email}</div> : null}
          </div>
          <div className='section-div'>
            <label htmlFor='phoneNumber'>
              <Translate>Phone:</Translate>
            </label>
            <input className='form-field' {...formik.getFieldProps('phoneNumber')} />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className='error'>{formik.errors.phoneNumber}</div>
            ) : null}
          </div>
          <div className='section-div'>
            <label>
              {' '}
              <Translate>Choose a profile icon:</Translate>
            </label>
          </div>
          <div className='img-flex-container'>
            {imageList.map((url, idx) => {
              return (
                <img
                  src={url}
                  key={idx}
                  onClick={() => {
                    handleProfilePicChange(url);
                    handleIconClick(url);
                  }}
                  className={selectedProfilePic === url ? 'img-selected' : ''}
                />
              );
            })}
          </div>
          {expandForm === true ? (
            <>
              <div className='section-div'>
                <label htmlFor='otpInput'>OTP:</label>
                <input type='number' className='form-field' id='otpInput' value={OTP} onChange={verifyOTP} />
              </div>
            </>
          ) : null}
          {expandForm === false ? (
            <button className='save-button' type='submit'>
              <Translate>Save</Translate>
            </button>
          ) : null}
          <div id='message'></div>
          <div>
            <Link to='/forgot-password' style={{ textDecoration: 'none' }}>
              <div className='reset-button'>
                <Translate>Reset Password</Translate>
              </div>
            </Link>
          </div>
        </form>
        <div id='recaptcha'></div>
      </Translator>
    );
  } else {
    return (
      <Translator from='en' to={language} googleApiKey={apiKey}>
        <MainNav />
        <h2>
          <Translate>Password Reset</Translate>
        </h2>
        <ForgotPassword />
        <div id='recaptcha'></div>
      </Translator>
    );
  }
};

export default connect(null, mapDispatchProps)(UserProfile);
