import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { data } from 'database/index';
import { getChannelInfo, getVideoId, setVideoInfo } from 'services/channelInfo';
import { getUserId } from 'services/userProfile';
import { GuideContext } from '../../context/GuideContext';
import FormModal from 'components/Guide/form-modal/FormModal';
import './Guide.styles.scss';
import { ChannelPopupContext } from '../../context/ChannelPopupContext';
import { ChevronLeftSvgIcon } from 'components/Guide/svg/general-icons/ChevronLeftSvgIcon';
import { ChevronRightSvgIcon } from 'components/Guide/svg/general-icons/ChevronRightSvgIcon';
import { If } from 'components/If';
import { useLanguage } from 'components/Translate/LanguageContext';
import { Translator, Translate } from 'react-auto-translate';
import { apiKey } from '../../../config.js';
import { getAuth } from 'firebase/auth';
import heartOutlined from './heart-outlined.png';
import heartFilled from './heart.png';

const mapStateProps = (state) => ({
  channelInfo: getChannelInfo(state),
  setVideoInfo,
  currentVideoId: getVideoId(state),
  userId: getUserId(state),
});

const CHANNEL_CATEGORIES = ['Favorites', ...Object.keys(data).map((channel) => channel)];
const FAVORITES_CATEGORY_TITLE = 'Favorites';

const TV_DATA = Object.entries(data);
const getSortedTVArr = (likedChannels) => {
  const favoriteChannels = TV_DATA.flatMap(([_, channels]) =>
    channels.filter((channel) => likedChannels[channel.number])
  );
  const uniqueFavoriteChannels = favoriteChannels.filter(
    (channel, index, self) => index === self.findIndex((t) => t.number === channel.number)
  );
  return [
    uniqueFavoriteChannels.length > 0 ? [FAVORITES_CATEGORY_TITLE, uniqueFavoriteChannels] : null,
    TV_DATA[0],
    TV_DATA[TV_DATA.length - 1],
    TV_DATA[TV_DATA.length - 2],
    ...TV_DATA.slice(1, TV_DATA.length - 2),
  ].filter(Boolean);
};

const DELAY = 700;

const Guide = ({ channelInfo, setVideoInfo, userId, currentVideoId }) => {
  const auth = getAuth();
  const user = auth.currentUser;

  const [channelCategories, setChannelCategories] = useState([...Object.keys(data).map((channel) => channel)]);
  useEffect(() => {
    if (user) {
      setChannelCategories(['Favorites', ...Object.keys(data).map((channel) => channel)]);
    } else {
      setChannelCategories([...Object.keys(data).map((channel) => channel)]);
    }
  }, [user]);

  const { language } = useLanguage();
  const isMobile = window.innerWidth < 750;
  const isDesktop = window.innerWidth >= 750 && window.innerWidth < 1700;
  const isLargeDesktop = window.innerWidth >= 1700;

  const [isChannelGuideActive, setIsChannelGuideActive] = useState(true);
  const [isMouseOrKeyboardActive, setIsMouseOrKeyboardActive] = useState(true);

  const { openFormModal, closeFormModal, isFormModalOpen, setIsFormModalOpen } = useContext(GuideContext);
  const { handleAgeVerification } = useContext(ChannelPopupContext);

  const [isChannelListOpen, setIsChannelListOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(isMobile ? 'Channel List' : CHANNEL_CATEGORIES[0]);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const [displayedChannels, setDisplayedChannels] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const scrollableContainerRef = useRef(null);
  const categoriesRefs = useRef({});
  const categoriesScrollableContainerRef = useRef({});
  const categoriesButtonRefs = useRef({});
  const categoriesButtonLargeDesktopRefs = useRef({});
  const autoScrollingTimeoutRef = useRef(null);
  const scrollTimeoutRef = useRef(null);

  const screenIsMobile = window.innerWidth < 750;

  const [isLiked, setIsLiked] = useState(false);
  const [likedChannels, setLikedChannels] = useState({});
  const MAX_FAVOURITES = 5;

  const toggleLike = (channelId) => {
    setLikedChannels((prevLikedChannels) => {
      const currentlyLiked = prevLikedChannels[channelId];
      const likedCount = Object.values(prevLikedChannels).filter(Boolean).length;

      if (currentlyLiked) {
        const updatedChannels = { ...prevLikedChannels, [channelId]: false };
        return updatedChannels;
      }

      if (likedCount < MAX_FAVOURITES) {
        const updatedChannels = { ...prevLikedChannels, [channelId]: true };
        return updatedChannels;
      } else {
        alert(`You can only favourite up to ${MAX_FAVOURITES} channels.`);
        return prevLikedChannels;
      }
    });
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleScreenResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);

    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, []);

  const handleSelectVideo = ({ channelName, number, channelDescription, channelIcon, channelLink, episodes }) => {
    const videoId = episodes[0].videoId;

    const selectedChannel = {
      channelName,
      channelNumber: number,
      channelDescription: channelDescription || '',
      channelIcon: channelIcon || '',
      channelLink: channelLink || '',
      videoId,
    };

    if (videoId === currentVideoId) return;

    handleAgeVerification(selectedChannel);
  };

  const SCROLL_AMOUNT = isDesktop ? 276.66 : 500;

  const scrollLeft = (node) => {
    if (!node) return;

    const currentScroll = node.scrollLeft;
    const newScrollPosition = currentScroll - SCROLL_AMOUNT;
    node.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
  };

  const scrollRight = (node) => {
    if (!node) return;

    const currentScroll = node.scrollLeft;
    const newScrollPosition = currentScroll + SCROLL_AMOUNT;
    node.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
  };

  const mouseScrollRight = (node) => {
    if (!node) return;

    const currentScroll = node.scrollLeft;
    const newScrollPosition = currentScroll - SCROLL_AMOUNT / 2;
    node.scrollTo({ left: newScrollPosition, behavior: 'auto' });
  };

  const mouseScrollLeft = (node) => {
    if (!node) return;

    const currentScroll = node.scrollLeft;
    const newScrollPosition = currentScroll + SCROLL_AMOUNT / 2;
    node.scrollTo({ left: newScrollPosition, behavior: 'auto' });
  };

  const mouseDragRight = (node, currentX) => {
    if (isDragging) {
      if (!node) return;

      const translate = currentX - startX;
      if (node) {
        const currentScroll = node.scrollLeft;
        const newScrollPosition = currentScroll + translate / 20;
        let delta = newScrollPosition - currentScroll;
        node.scrollBy({ left: delta, behavior: 'auto' });
      }
    }
  };

  const mouseDragLeft = (node, currentX) => {
    if (isDragging) {
      if (!node) return;

      let translate = currentX - startX;
      if (translate < 0) {
        translate *= -1;
      }
      const currentScroll = node.scrollLeft;
      const newScrollPosition = currentScroll - translate / 20;
      let delta = newScrollPosition - node.scrollLeft;
      node.scrollBy({ left: delta, behavior: 'auto' });
    }
  };

  const toggleChannelListDropdown = () => {
    setIsChannelListOpen((isChannelListOpen) => !isChannelListOpen);
  };

  useEffect(() => {
    const favoriteChannels = TV_DATA.flatMap(([_, channels]) =>
      channels.filter((channel) => likedChannels[channel.number])
    );
    const uniqueFavoriteChannels = favoriteChannels.filter(
      (channel, index, self) => index === self.findIndex((t) => t.number === channel.number)
    );
    setDisplayedChannels(uniqueFavoriteChannels);
  }, [likedChannels]);

  const selectChannelCategory = (category) => {
    setCurrentCategory(category);
    setIsChannelListOpen(false);
    setIsAutoScrolling(true);

    const sortedTVArr = getSortedTVArr(likedChannels);
    if (category === 'Favorites' && !sortedTVArr[0]) {
      return;
    }

    const categoryChannels = sortedTVArr.filter((channel) => channel && channel[0] === category);
    setDisplayedChannels(categoryChannels.length > 0 ? categoryChannels[0][1] : []);
    if (categoriesRefs.current[category]) {
      categoriesRefs.current[category].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (autoScrollingTimeoutRef.current) clearTimeout(autoScrollingTimeoutRef.current);
    autoScrollingTimeoutRef.current = setTimeout(() => setIsAutoScrolling(false), DELAY);
  };

  useEffect(() => {
    const SCROLLABLE_CONTAINER_TOP_EDGE = scrollableContainerRef.current.getBoundingClientRect().top;
    const SCROLLABLE_CONTAINER_BOTTOM_EDGE = scrollableContainerRef.current.getBoundingClientRect().bottom;

    const checkIfCategoryIsInView = (categoryRef) => {
      const categoryTopEdge = categoryRef.getBoundingClientRect().top;
      const categoryBottomEdge = categoryRef.getBoundingClientRect().bottom;

      return (
        categoryTopEdge <= SCROLLABLE_CONTAINER_TOP_EDGE &&
        categoryBottomEdge < SCROLLABLE_CONTAINER_BOTTOM_EDGE &&
        categoryBottomEdge > SCROLLABLE_CONTAINER_TOP_EDGE
      );
    };

    const updateCurrentCategory = () => {
      const categories = categoriesRefs.current;

      const checkIfCategoryIsInView = (categoryRef) => {
        const SCROLLABLE_CONTAINER_TOP_EDGE = scrollableContainerRef.current.getBoundingClientRect().top;
        const SCROLLABLE_CONTAINER_BOTTOM_EDGE = scrollableContainerRef.current.getBoundingClientRect().bottom;

        const categoryTopEdge = categoryRef.getBoundingClientRect().top;
        const categoryBottomEdge = categoryRef.getBoundingClientRect().bottom;

        return (
          categoryTopEdge <= SCROLLABLE_CONTAINER_TOP_EDGE &&
          categoryBottomEdge < SCROLLABLE_CONTAINER_BOTTOM_EDGE &&
          categoryBottomEdge > SCROLLABLE_CONTAINER_TOP_EDGE
        );
      };

      Object.values(categories).forEach((categoryRef) => {
        if (!checkIfCategoryIsInView(categoryRef)) return;

        const newCategoryIndex = CHANNEL_CATEGORIES.findIndex((category) => categories[category] === categoryRef);
        const newCurrentCategory = CHANNEL_CATEGORIES[newCategoryIndex];
        setCurrentCategory(newCurrentCategory);
      });
    };

    let lastScrollTop = 0;
    const FAST_SCROLL_THRESHOLD = 100;

    const handleScroll = () => {
      const currentScrollTop = scrollableContainerRef.current.scrollTop;
      const scrollDifference = Math.abs(currentScrollTop - lastScrollTop);

      if (isAutoScrolling || scrollDifference > FAST_SCROLL_THRESHOLD) return;

      updateCurrentCategory();

      scrollTimeoutRef.current = setTimeout(() => {
        updateCurrentCategory();
        lastScrollTop = scrollableContainerRef.current.scrollTop;
      }, DELAY);
    };

    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableContainerRef.current) {
        scrollableContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isAutoScrolling]);

  const updateButtonVisibility = (categoryTitle, scrollContainer) => {
    const leftButton = categoriesButtonRefs.current[categoryTitle]?.left;
    const rightButton = categoriesButtonRefs.current[categoryTitle]?.right;

    if (leftButton && rightButton) {
      leftButton.style.display = scrollContainer.scrollLeft === 0 ? 'none' : 'flex';
      leftButton.style.userSelect = scrollContainer.scrollLeft === 0 ? 'none' : 'auto';

      const isScrolledToRight = scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth;
      rightButton.style.display = isScrolledToRight ? 'none' : 'flex';
      rightButton.style.userSelect = isScrolledToRight ? 'none' : 'auto';
    }
  };

  useEffect(() => {
    if (!isDesktop) return;

    handleScrollButtonsVisibility();

    const handleScroll = (event) => {
      const title = Object.keys(categoriesScrollableContainerRef.current).find(
        (key) => categoriesScrollableContainerRef.current[key] === event.target
      );
      updateButtonVisibility(title, categoriesScrollableContainerRef.current[title]);
    };

    Object.values(categoriesScrollableContainerRef.current).forEach((scrollContainer) => {
      scrollContainer.addEventListener('scroll', handleScroll);
    });
    return () =>
      Object.values(categoriesScrollableContainerRef.current).forEach((scrollContainer) =>
        scrollContainer.removeEventListener('scroll', handleScroll)
      );
  }, [isDesktop]);

  const handleScrollButtonsVisibility = () => {
    Object.entries(categoriesScrollableContainerRef.current).forEach(([categoryTitle, scrollContainer]) => {
      updateButtonVisibility(categoryTitle, scrollContainer);
    });
  };

  const handleScrollButtonsVisibilityOnLargeDesktop = () => {
    const container = scrollableContainerRef.current;
    const leftButton = categoriesButtonLargeDesktopRefs.current[0].left;
    const rightButton = categoriesButtonLargeDesktopRefs.current[1].right;

    leftButton.style.display = container.scrollLeft === 0 ? 'none' : 'flex';
    leftButton.style.userSelect = container.scrollLeft === 0 ? 'none' : 'auto';

    const isScrolledToRight = container.scrollLeft + container.clientWidth === container.scrollWidth;
    rightButton.style.display = isScrolledToRight ? 'none' : 'flex';
    rightButton.style.userSelect = isScrolledToRight ? 'none' : 'auto';
  };

  useEffect(() => {
    if (!isLargeDesktop) return;

    handleScrollButtonsVisibilityOnLargeDesktop();

    const handleScroll = () => {
      handleScrollButtonsVisibilityOnLargeDesktop();
    };
    scrollableContainerRef.current.addEventListener('scroll', handleScroll);
    return () => scrollableContainerRef.current.removeEventListener('scroll', handleScroll);
  }, [isLargeDesktop]);

  const colors = {
    1: ['hsl(227 47.2% 21.0%)', 'hsl(298 34.4% 15.3%)'],
    2: ['hsl(298 34.4% 15.3%)', 'hsl(214 59.4% 15.3%)'],
    3: ['hsl(214 59.4% 15.3%)', 'hsl(55 3.7% 19.9%)'],
    4: ['hsl(55 3.7% 19.9%)', 'hsl(319 41.0% 16.0%)'],
    5: ['hsl(319 41.0% 16.0%)', 'hsl(41 16.4% 15.6%)'],
    6: ['hsl(41 16.4% 15.6%)', 'hsl(335 47.2% 19.3%)'],
    7: ['hsl(335 47.2% 19.3%)', 'hsl(227 50.0% 24.1%)'],
    8: ['hsl(227 50.0% 19.1%)', 'hsl(192 92.6% 15.1%)'],
    9: ['hsl(192 92.6% 15.1%)', 'hsl(172 75.8% 10.1%)'],
    10: ['hsl(172 75.8% 10.1%)', 'hsl(297 36.8% 18.3%)'],
  };

  let increment = 0;
  const incrementColorsObject = () => {
    if (increment === 10) increment = 0;
    increment++;
    return increment;
  };
  const handleWheel = (event) => {
    if (event.deltaY < 0) {
      mouseScrollLeft(scrollableContainerRef.current);
    } else {
      mouseScrollRight(scrollableContainerRef.current);
    }
  };
  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.clientX);
  };
  const handleMouseMove = (event) => {
    if (!isDragging) {
      return;
    }
    const x = event.clientX;
    if (x > startX) {
      mouseDragRight(scrollableContainerRef.current, x);
    } else if (x < startX) {
      mouseDragLeft(scrollableContainerRef.current, x);
    }
  };

  const handleMouseUp = (event) => {
    setIsDragging(false);
    setStartX(event.clientX);
  };

  const handleInactivityTimer = () => {
    setIsChannelGuideActive(false);
  };

  const handleFormModal = () => {
    if (isFormModalOpen) {
      closeFormModal();
      setIsFormModalOpen(false);
    } else {
      openFormModal();
      setIsFormModalOpen(true);
    }
  };

  const inactivityTimerRef = useRef(null);

  const showChannelGuide = () => {
    setIsChannelGuideActive(true);
    setIsMouseOrKeyboardActive(true);
    clearTimeout(inactivityTimerRef.current);
    inactivityTimerRef.current = setTimeout(handleInactivityTimer, 30000);
  };

  useEffect(() => {
    const handleActivity = () => {
      setIsMouseOrKeyboardActive(true);
      clearTimeout(inactivityTimerRef.current);
      inactivityTimerRef.current = setTimeout(handleInactivityTimer, 30000);
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    return () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      const channelList = document.querySelector('.gntv_guide__channel-name-list-container');
      if (channelList && !channelList.contains(event.target) && isChannelListOpen) {
        toggleChannelListDropdown();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isChannelListOpen]);

  return (
    <>
      {isChannelGuideActive || screenIsMobile ? (
        <section className={`gntv_guide__container ${screenWidth < 750 ? 'mobile-guide' : ''}`}>
          {/**left right button */}
          <If test={isLargeDesktop}>
            <button
              className='gntv_guide__large-desktop-scroll-button-left'
              ref={(button) => {
                if (!categoriesButtonLargeDesktopRefs.current[0]) categoriesButtonLargeDesktopRefs.current[0] = {};
                categoriesButtonLargeDesktopRefs.current[0].left = button;
              }}
              onClick={() => scrollLeft(scrollableContainerRef.current)}
            >
              <ChevronLeftSvgIcon />
            </button>
            <button
              className='gntv_guide__large-desktop-scroll-button-right'
              ref={(button) => {
                if (!categoriesButtonLargeDesktopRefs.current[1]) categoriesButtonLargeDesktopRefs.current[1] = {};
                categoriesButtonLargeDesktopRefs.current[1].right = button;
              }}
              onClick={() => scrollRight(scrollableContainerRef.current)}
            >
              <ChevronRightSvgIcon />
            </button>
          </If>

          {/**Side category bars */}
          {isMobile ? (
            <>
              <Translator from='en' to={language} googleApiKey={apiKey}>
                <div className='gntv_guide__header-container'>
                  <div className='gntv_guide__channel-name-list-container'>
                    <button className='gntv_guide__channel-name-list-button' onClick={toggleChannelListDropdown}>
                      <span className='gntv_guide__header'>
                        <Translate>{currentCategory}</Translate>
                      </span>
                    </button>
                    <div className='gntv_guide__categories-container' data-active={isChannelListOpen}>
                      {CHANNEL_CATEGORIES.slice(user ? 0 : 1).map((category) => (
                        <button key={category} onClick={() => selectChannelCategory(category)}>
                          <span>
                            <Translate>{category}</Translate>
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </Translator>
            </>
          ) : (
            <>
              <Translator from='en' to={language} googleApiKey={apiKey}>
                <div className='gntv_guide_sidebar-container'>
                  {CHANNEL_CATEGORIES.slice(user ? 0 : 1).map((category) => (
                    <button
                      key={category}
                      className='gntv_guide__sidebar-item-buttons'
                      onClick={() => selectChannelCategory(category)}
                    >
                      <Translate>{category}</Translate>
                    </button>
                  ))}
                </div>
              </Translator>
            </>
          )}

          <div ref={scrollableContainerRef} className='gntv_guide__list-container'>
            {getSortedTVArr(likedChannels).map((category) => {
              const title = category[0];
              const channels = category[1];
              const isFavorites = title === FAVORITES_CATEGORY_TITLE;

              return (
                title && (
                  <div
                    key={title}
                    ref={(channelTitle) => (categoriesRefs.current[title] = channelTitle)}
                    className='gntv_guide__items'
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onWheel={handleWheel}
                    style={{
                      position: isFavorites ? 'absolute' : 'relative',
                      top: isFavorites ? '0px' : 'auto',
                      zIndex: isFavorites ? 10 : 1,
                    }}
                  >
                    {/**Channel scroll button */}
                    <If test={isDesktop}>
                      <button
                        ref={(button) => {
                          if (!categoriesButtonRefs.current[title]) categoriesButtonRefs.current[title] = {};
                          categoriesButtonRefs.current[title].left = button;
                        }}
                        className='gntv_guide__scroll-button-left'
                        onClick={() => scrollLeft(categoriesScrollableContainerRef.current[title])}
                      >
                        <ChevronLeftSvgIcon />
                      </button>
                      <button
                        ref={(button) => {
                          if (!categoriesButtonRefs.current[title]) categoriesButtonRefs.current[title] = {};
                          categoriesButtonRefs.current[title].right = button;
                        }}
                        className='gntv_guide__scroll-button-right'
                        onClick={() => scrollRight(categoriesScrollableContainerRef.current[title])}
                      >
                        <ChevronRightSvgIcon />
                      </button>
                    </If>

                    <Translator from='en' to={language} googleApiKey={apiKey}>
                      {/**The category title */}
                      <div className='gntv_guide__title-container'>
                        <span className='gntv_guide__title'>
                          <Translate>{title}</Translate>
                        </span>
                        <hr />
                      </div>

                      {/**The channel wrappers  */}
                      <div
                        className='gntv_guide__channel-button-wrapper'
                        ref={(channelTitle) => (categoriesScrollableContainerRef.current[title] = channelTitle)}
                      >
                        {channels.map((channel) => {
                          const index = incrementColorsObject();
                          const firstColor = colors[index][0];
                          const secondColor = colors[index][1];
                          const isFavorited = likedChannels[channel.number];
                          return (
                            <button
                              key={channel.number}
                              className={`gntv_guide__channel-button ${
                                likedChannels[channel.number] ? 'favorited' : ''
                              }`}
                              onClick={() => handleSelectVideo(channel)}
                              style={{
                                background: isLargeDesktop
                                  ? `linear-gradient(120deg, ${firstColor}, ${secondColor})`
                                  : '',
                                border:
                                  channel.episodes[0].videoId === currentVideoId ? '2px solid hsl(319, 100%, 90%)' : '',
                              }}
                            >
                              <div className='gntv_guide__channel-button-container'>
                                <div className='gn-tv_guide_channel-image-container'>
                                  <img src={channel.channelIcon} alt={'icon'} />
                                </div>
                                <div className='gntv_guide__channel-text-container'>
                                  <span className='gntv_guide__channel-number'>{channel.number}</span>
                                  <span className='gntv_guide__channel-name'>
                                    <Translate>{channel.channelName}</Translate>
                                  </span>
                                </div>
                                {user && (
                                  <div
                                    className={`heart-icon${isMobile ? '-mobile' : ''} ${isFavorited ? 'liked' : ''}`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleLike(channel.number);
                                    }}
                                  ></div>
                                )}
                              </div>
                            </button>
                          );
                        })}
                      </div>
                    </Translator>
                  </div>
                )
              );
            })}

            <Translator from='en' to={language} googleApiKey={apiKey}>
              <div className='gntv_guide__ggi-button'>
                <button onClick={handleFormModal}>
                  <Translate>Geaux Get It</Translate>
                </button>
              </div>
            </Translator>
          </div>
        </section>
      ) : (
        <Translator from='en' to={language} googleApiKey={apiKey}>
          <div className='purple-tab' onClick={showChannelGuide}>
            <div className='guide_container'>
              <button className='guid_button'>
                <Translate>Show Guide</Translate>
              </button>
            </div>
          </div>
        </Translator>
      )}

      {user ? <FormModal /> : null}
    </>
  );
};

export default connect(mapStateProps)(Guide);
