import './styles.scss';
import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { setUser } from '../../services/userProfile';
import { FormikField } from '../../components/FormikField';
import { MainNav } from '../../components/MainNav';
import { db } from '../../firebase-config';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import useUserInternal, { UserContext, useUser } from '../../hooks/use-user';
import googleLogo from '../../assets/icons/Sign_In_Page_Icons/google.png';
import phoneIcon from '../../assets/icons/Sign_In_Page_Icons/phone.png';
import { Translator, Translate } from 'react-auto-translate';
import TranslateOpt from '../../components/Translate/index.js';
import { apiKey } from '../../../config.js';
import {
  PLAYER_ROUTE,
  SIGN_UP_ROUTE,
  RESET_PASSWORD_ROUTE,
  HOME_ROUTE,
  CHANNEL_ROUTE,
  FORGOT_PASSWORD_ROUTE,
} from 'pages/Routes';
import SigninNavbar from 'components/SigninNavbar';
import Footer from 'components/Footer';
import SHOW_PASSWORD from '../../assets/icons/Profile_Page/show-password.png';
import DONT_SHOW_PASSWORD from '../../assets/icons/Profile_Page/dont-show-password.png';

import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';

// import './styles.scss';
import { useLanguage } from 'components/Translate/LanguageContext';

const mapDispatchProps = {
  setUser,
};

const initialValues = {
  password: '',
  email: '',
};

const passwordChecker = (val = '') => {
  // Enforces at least one uppercase, one lowercase, one number, one special character, no spaces, and at least 8 characters
  const validPassword = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W])(?!.*\s).{8,}/;
  if (val.match(validPassword)) {
    // console.log('Yay it works');
    return true;
  } else {
    // console.log("Doesn't work");
    return false;
  }
};

const SigninSchema = Yup.object().shape({
  password: Yup.string()
    .required(<Translate>Required</Translate>)
    .min(8, <Translate>Must be at least 8 characters</Translate>) // Had to hardcode the 8 in order for the Translate comoponent to be used
    .max(100, <Translate>Password cannot be more than 100 characters</Translate>)
    .test({
      name: 'passwordChecker',
      message: <Translate>Numbers, symbols, uppercase and lowercase characters required</Translate>,
      test: passwordChecker,
    }),
  email: Yup.string().required(<Translate>Required</Translate>),
});

const SignIn = (props) => {
  //States
  const [error, setError] = useState('');
  const [users, setUsers] = useState([]);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Used to adjust the window width for responsiveness
  const [showPassword, setShowPassword] = useState(false);

  const usersCollectionRef = collection(db, 'users');
  const auth = getAuth();

  const userState = useUser();
  const contextValue = useContext(UserContext);
  // console.log('context value is', contextValue);

  const clearAgeLocalStorage = () => {
    localStorage.removeItem('isOver16');
  };

  useEffect(() => {
    clearAgeLocalStorage();
  }, []);

  //componnetDidMount
  const getUserInfo = async () => {
    const fbData = await getDocs(usersCollectionRef);
    setUsers(
      fbData.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
    );
  };

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowWidth();

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  const createUser = async (data) => {
    await addDoc(usersCollectionRef, { ...data });
  };

  const onSubmit = (values) => {
    const formatEmail = values.email.toLowerCase();

    // console.log('123345')
    // console.log(auth.currentUser)
    // debugger;

    signInWithEmailAndPassword(auth, formatEmail, values.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        // Record login time
        const loginTime = new Date().getTime();
        localStorage.setItem('loginTime', loginTime);

        //const userInfo = users.find((user) => user.uid === userAuth.uid);
        props.setUser(user);
        // console.log(user);
        window.location.assign(PLAYER_ROUTE); //should take to the player page
      })
      .catch((error) => {
        const errorMessage = error.message;
        setError(<Translate>Incorrect username or password</Translate>);
      });
  };

  const onGoogleClick = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const userAuth = result.user;

        const newUserInfo = {
          name: userAuth.displayName,
          email: userAuth.email,
          shoppingCart: [],
          uid: userAuth.uid,
          createdBy: 'Google',
        };

        const userInfo = users.find((user) => user.uid === userAuth.uid);

        // Record login time
        const loginTime = new Date().getTime();
        localStorage.setItem('loginTime', JSON.stringify(loginTime));

        if (userInfo) {
          props.setUser(userInfo);
          // console.log(userInfo);
        } else {
          createUser(newUserInfo);
        }

        window.location.assign(PLAYER_ROUTE); //should take to the player page
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        setError(<Translate>Incorrect username or password</Translate>);
      });
  };

  const onPhoneClick = () => {
    setUpRecaptcha();

    // const phoneNumber = "+14075551234"; // testing number, OTP is 123456
    const rawNum = window.prompt('Enter Phone Number: (SMS Data Rates May Apply)');
    const phoneNumber = formatNumber(rawNum);
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;

        // STEP 4
        // const code = getCodeFromUserInput();

        const code = window.prompt('Enter OTP');
        confirmationResult
          .confirm(code)
          .then(async (result) => {
            // console.log('User signed in successfully.');
            const userAuth = result.user;
            // const newUserInfo = {
            //   name: userAuth.displayName,
            //   email: userAuth.email,
            //   phone: userAuth.phoneNumber,
            //   shoppingCart: [],
            //   uid: userAuth.uid,
            //   createdBy: 'Geaux',
            // };

            // Check if the user already exists in the database
            const existingUser = users.find((user) => user.uid === userAuth.uid);

            // const userInfo = users.find((user) => user.uid === userAuth.uid);
            // Record login time
            const loginTime = new Date().getTime();
            localStorage.setItem('loginTime', JSON.stringify(loginTime));

            if (existingUser) {
              // User already exists, set the existing user's data
              props.setUser(existingUser);
              // console.log(existingUser);
              window.location.assign(PLAYER_ROUTE);
            } else {
              setError('User account does not exist with that phone number');
            }

            // window.location.assign(PLAYER_ROUTE); // Redirect to the player page
          })
          .catch((err) => {
            // Handle errors related to verification code
            switch (err.code) {
              case 'auth/missing-code':
                setError('A code was not submitted, Please Try again');
                break;
              case 'auth/invalid-verification-code':
                setError('Invalid Verification Code, Please Try again');
                break;
              default:
                setError('There has been an error signing you in, Please refresh and try again');
            }
          });
      })
      .catch((err) => {
        // Handle errors related to SMS sending
        // console.log(err);
        setError('There has been an error signing you in, Please refresh and try again');
      });
  };

  const setUpRecaptcha = () => {
    // STEP 2
    if (!window.recaptchaVerifier) {
      const auth = getAuth();
      window.recaptchaVerifier = new RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // console.log('Recaptcha resolved');
            onSignInSubmit();
          },
        },
        auth
      );
    }
  };

  const formatNumber = (num) => {
    // Check if num is null, undefined, or empty string
    if (num === undefined || num === null || num === '') {
      console.log('Invalid phone number format'); // Handle the error case
      return; // Exit the function
    }
  
    let phoneNumberString = num.toString();
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(
      /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/
    );
    if (match) {
      match = match[0];
      match[0] === '1' ? (match = '+' + match) : (match = '+1' + match);
      return match;
    }
    console.log('Invalid phone number format'); // Handle the case for invalid format
    return;
  };
  
  const onPasswordFocus = () => {
    setPasswordFocused(true);
  };

  const onPasswordBlur = () => {
    setPasswordFocused(false);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const { language, changeLanguage } = useLanguage();

  return (
    <Translator from='en' to={language} googleApiKey={apiKey}>
      <div className='signin-page-container'>
        <SigninNavbar />
        <div className='signin-boxi'>
          <h1 className='signin-headeri'>
            <Translate>Welcome Back!</Translate>
          </h1>
          <p className='signin-subheaderi'>
            <Translate>Don't have an account?</Translate>
            <a href={SIGN_UP_ROUTE} className='signin-linki'>
              <Translate>Sign up here</Translate>
            </a>
          </p>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={SigninSchema}>
            {(props) => {
              return (
                <Form className='form-container'>
                  <FormikField label={<Translate>Email</Translate>} name='email' type='email' />
                  <div className='password-field-container'>
                    <FormikField
                      label={<Translate>Password</Translate>}
                      name='password'
                      type={showPassword === false ? 'password' : 'text'}
                      onBlur={onPasswordBlur}
                      onFocus={onPasswordFocus}
                    />
                    <img
                      src={showPassword === false ? DONT_SHOW_PASSWORD : SHOW_PASSWORD}
                      alt=''
                      className='password-eye-icon'
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  <div>
                    <Link to={FORGOT_PASSWORD_ROUTE} className='gn-forgoti-password'>
                      <Translate>Forgot your Password?</Translate>
                    </Link>
                  </div>
                  <div className='gn-sign_up-error'>{/* This one is just for spacing */}</div>
                  <button className='gn-signup-buttoni' type='submit'>
                    <Translate>Sign in!</Translate>
                  </button>
                  <div className='gn-sign_up-error'> {error}</div>
                  <div className='sign-up-line-container'>
                    <div className='horizontal-line'></div>
                    <span className='or-message'>
                      <Translate>Or sign in with</Translate>
                    </span>
                    <div className='horizontal-line'></div>
                  </div>
                  <div className='gn-sign_in-oauthorization'>
                    <img className='gn-google-image' src={googleLogo} onClick={onGoogleClick} />
                    <img className='gn-phone-image' src={phoneIcon} onClick={onPhoneClick} />
                  </div>
                  <div id='sign-in-button'></div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Footer />
      </div>
    </Translator>
  );
};

export default connect(null, mapDispatchProps)(SignIn);
