import './styles.scss';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { PasswordTooltip } from 'components/PasswordTooltip';
import { FormikField } from 'components/FormikField';
import { MainNav } from 'components/MainNav';
import { passwordSchema } from 'util/schema/password';
import { db } from '../../firebase-config';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import regeneratorRuntime from 'regenerator-runtime';
import googleLogo from '../../assets/icons/Sign_In_Page_Icons/google.png';
import phoneIcon from '../../assets/icons/Sign_In_Page_Icons/phone.png';
import avatar from '../../assets/icons/Sign_In_Page_Icons/user.png';
import { setUser } from 'services/userProfile';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/core/styles';
import { Translator, Translate } from 'react-auto-translate';
import TranslateOpt from '../../components/Translate/index.js';
import { apiKey } from '../../../config.js';
import { useHistory } from 'react-router-dom';
import { CHANNEL_ROUTE, PLAYER_ROUTE, SIGN_IN_ROUTE } from 'pages/Routes';
import SignupNavbar from 'components/SignupNavbar';
import { useLanguage } from 'components/Translate/LanguageContext';
import Footer from 'components/Footer';
import SHOW_PASSWORD from '../../assets/icons/Profile_Page/show-password.png';
import DONT_SHOW_PASSWORD from '../../assets/icons/Profile_Page/dont-show-password.png';

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';

const mapDispatchProps = {
  setUser,
};

const initialValues = {
  name: '',
  email: '',
  password: '',
  confirm_password: '',
};

// Handles using the Translate component to translate the error message in the matches method(This wasn't working by simply placing it as a second argument for the method)
const handleTranslation = () => {
  return <Translate>Invalid characters: &lt;&gt;&#59;\&quot;/</Translate>;
};

const passwordChecker = (val = '') => {
  // Enforces at least one uppercase, one lowercase, one number, one special character, no spaces, and at least 8 characters
  const validPassword = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W])(?!.*\s).{8,}/;
  if (val.match(validPassword)) {
    // console.log('Yay it works');
    return true;
  } else {
    // console.log("Doesn't work");
    return false;
  }
};

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .required(<Translate>Required</Translate>)
    .max(
      50,
      <>
        <Translate>Cannot exceed </Translate>50<Translate> characters</Translate>
      </>
    )
    .matches(/^((?![<>;"/\\/]+).)*$/, handleTranslation),
  email: Yup.string().required(<Translate>Required</Translate>),
  password: Yup.string()
    .required(<Translate>Required</Translate>)
    .notOneOf([Yup.ref('email')], <Translate>Password cannot be the same as email</Translate>)
    .min(8, <Translate>Must be at least 8 characters</Translate>) // Had to hardcode the 8 in order for the Translate comoponent to be used
    .max(100, <Translate>Password cannot be more than 100 characters</Translate>)
    .test({
      name: 'passwordChecker',
      message: <Translate>Numbers, symbols, uppercase and lowercase characters required</Translate>,
      test: passwordChecker,
    }),
});

const CreateAccount = (props) => {
  //States
  const [error, setError] = useState('');
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [users, setUsers] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const userCollectionRef = collection(db, 'users');
  const auth = getAuth();

  const { language, changeLanguage } = useLanguage();

  //componentDidMount
  const getUserInfo = async () => {
    const fbData = await getDocs(userCollectionRef);
    setUsers(fbData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const createUser = async (data) => {
    await addDoc(userCollectionRef, { ...data });
  };

  const goToNextSection = () => {
    props.history.push(PLAYER_ROUTE);
  };

  // const playerUrl = () => {
  //   const history = useHistory(); //came from merge conflict, unsure of difference between this and props.history.push, but kept
  //   history.push(PLAYER_ROUTE);
  // };

  const onSubmit = async (values) => {
    const formatEmail = values.email.toLowerCase();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formatEmail, values.password);
      const user = userCredential.user;

      if (user) {
        const userInfo = {
          age: 0,
          createdAt: new Date(),
          createdBy: 'Email',
          email: formatEmail,
          emailVerified: false,
          favoriteChannels: [],
          gender: 'not specified',
          lastLogin: new Date(),
          name: values.name,
          birthday: '1900/01/01',
          notes: null,
          phone: null,
          photoURL: avatar,
          shoppingCart: [],
          uid: user.uid,
          updateAt: null,
        };

        console.log('UserInfo:', userInfo);

        await props.setUser(userInfo);
        await createUser(userInfo);

        window.location.href = PLAYER_ROUTE;
      }
    } catch (err) {
      if (err.code === 'auth/email-already-in-use') {
        setError('An account with this email already exists');
      } else if (err.code === 'auth/invalid-email') {
        setError('Invalid email, please try again');
      } else {
        setError('There has been an error creating your account. Please refresh and try again.');
      }
      console.error('Error:', err);
    }
  };

  const onGoogleClick = () => {
    console.log('google click');
    //debugger;

    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const userAuth = result.user;

        const newUserInfo = {
          age: 0,
          createdAt: new Date(),
          createdBy: 'Google',
          email: userAuth.email,
          emailVerified: false,
          favoriteChannels: [],
          gender: 'not specified',
          lastLogin: new Date(),
          name: userAuth.displayName,
          birthday: '1900/01/01',
          notes: null,
          phone: null,
          photoURL: '',
          shoppingCart: [],
          uid: userAuth.uid,
          updateAt: null,
        };

        const userInfo = users.find((user) => user.uid === userAuth.uid);

        if (userInfo) {
          setError('Account already exists please login instead');
        } else {
          createUser(newUserInfo);
          window.location.href = PLAYER_ROUTE;
        }

        // window.location.href = PLAYER_ROUTE;
      })
      .catch((error) => {
        // console.log(error);
        if (error.code === 'auth/account-exists-with-different-credential') {
          setError('Account already exists with that email');
        } else if (error.code === 'auth/popup-closed-by-user') {
          setError('');
        }
        // // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        else {
          setError('There has been an error creating your account, Please refresh and try again');
        }
      });
  };

  const onPhoneClick = () => {
    setUpRecaptcha();
    // const phoneNumber = "+14075551234"; // testing number, OTP is 123456
    const rawNum = window.prompt('Enter Phone Number: (SMS Data Rates May Apply)');
    const phoneNumber = formatNumber(rawNum);
    const appVerifier = window.recaptchaVerifier;
    // console.log('do we get here?');
    // console.log(phoneNumber);
    // console.log(appVerifier);

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // console.log(phoneNumber);
        // console.log(auth);
        // console.log(appVerifier);
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...

        // STEP 4
        // const code = getCodeFromUserInput();
        const code = window.prompt('Enter OTP');
        confirmationResult
          .confirm(code)
          .then(async (result) => {
            // console.log('User signed in successfully.');
            const userAuth = result.user;

            // Check if the user already exists in the database
            const existingUser = users.find((user) => user.uid === userAuth.uid);

            // Record login time
            const loginTime = new Date().getTime();
            localStorage.setItem('loginTime', JSON.stringify(loginTime));
            // console.log(existingUser);

            if (existingUser) {
              // User already exists, set the existing user's data
              setError('Account with that number exists, please login instead');
            } else {
              // User does not exist, create a new user
              const newUserInfo = {
                name: userAuth.displayName || 'New User',
                email: userAuth.email,
                phone: userAuth.phoneNumber,
                birthday: '1900/01/01',
                shoppingCart: [],
                uid: userAuth.uid,
                createdBy: 'Geaux',
              };

              // Create the user in your database
              await createUser(newUserInfo);
              // Set the new user's data
              props.setUser(newUserInfo);
              window.location.assign(PLAYER_ROUTE); // Redirect to the player page
            }

            // window.location.assign(PLAYER_ROUTE); // Redirect to the player page
          })
          .catch((err) => {
            // Handle errors related to verification code
            switch (err.code) {
              case 'auth/missing-code':
                setError('A code was not submitted, Please Try again');
                break;
              case 'auth/invalid-verification-code':
                setError('Invalid Verification Code, Please Try again');
                break;
              default:
                setError('There has been an error signing you in, Please refresh and try again');
            }
          });
      })
      .catch((err) => {
        // Handle errors related to SMS sending
        if (err.code === 'auth/captcha-check-failed') {
          setError('Captcha check failed please try again');
        } else if (err.code === 'auth/invalid-phone-number') {
          setError('Invalid number please try again');
        } else if (err.code === 'auth/quota-exceeded') {
          setError('SMS quota exceed please try at another time');
        } else if (err.code === 'auth/user-disabled') {
          setError('User account disabled unable to fulfill request');
        }
        // console.log(err);
        else {
          setError('There has been an error signing you in, Please refresh and try again');
        }
        // console.log(err);
        //
      });
  };

  const setUpRecaptcha = () => {
    // STEP 2
    if (!window.recaptchaVerifier) {
      try {
        const auth = getAuth();
        // console.log(auth);
        window.recaptchaVerifier = new RecaptchaVerifier(
          'sign-in-button',
          {
            size: 'invisible',
            callback: (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // console.log('Recaptcha resolved');
            },
          },
          auth
        );
      } catch (error) {
        if (error.code === 'auth/argument-error') {
          setError('Error getting recaptcha please try again');
          // console.log(error);
        } else {
          setError('Recaptcha failed please try again');
        }
      }
      // } else {
      //   setError('Recaptcha failed please try again');
      // }
    }
    // console.log(error);
  };

  const formatNumber = (num) => {
    // Check if num is null, undefined, or empty string
    if (num === undefined || num === null || num === '') {
      console.log('Invalid phone number format'); // Handle the error case
      return; // Exit the function
    }

    let phoneNumberString = num.toString();
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(
      /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/
    );
    if (match) {
      match = match[0];
      match[0] === '1' ? (match = '+' + match) : (match = '+1' + match);
      return match;
    }
    console.log('Invalid phone number format'); // Handle the case for invalid format
    return;
  };

  const onPasswordFocus = () => {
    setPasswordFocused(true);
  };

  const onPasswordBlur = () => {
    setPasswordFocused(false);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 750);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 750);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Translator from='en' to={language} googleApiKey={apiKey}>
      {/*<TranslateOpt language={language} handleChange={handleLanguageChange} />*/}
      <div className='signin-page-container'>
        <SignupNavbar />
        <div className='signin-boxi'>
          <h1 className='signin-headeri'>
            <Translate>Welcome!</Translate>
          </h1>
          <p className='signin-subheaderi'>
            <Translate>Already have an account?</Translate>
            <a href={SIGN_IN_ROUTE} className='signin-linki'>
              <Translate>Sign in here</Translate>
            </a>
          </p>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={SignupSchema}>
            {(props) => {
              return (
                <Form className='form-container'>
                  <FormikField label={<Translate>Name</Translate>} name='name' />
                  <FormikField label={<Translate>Email</Translate>} name='email' type='email' />
                  <div className='password-field-container'>
                    <FormikField
                      label={<Translate>Password</Translate>}
                      name='password'
                      type={showPassword === false ? 'password' : 'text'}
                      onBlur={onPasswordBlur}
                      onFocus={onPasswordFocus}
                    />
                    <img
                      src={showPassword === false ? DONT_SHOW_PASSWORD : SHOW_PASSWORD}
                      alt=''
                      className='password-eye-icon'
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  {isWideScreen && <PasswordTooltip isFocused={passwordFocused} password={props.values.password} />}
                  <button className='gn-signup-buttoni' type='submit'>
                    <Translate>Sign up!</Translate>
                  </button>
                  <div className='gn-sign_up-error'>
                    <Translate>{error}</Translate>
                  </div>
                  <div className='sign-up-line-container'>
                    <div className='horizontal-line'></div>
                    <span className='or-message'>
                      <Translate>Or sign up with</Translate>
                    </span>
                    <div className='horizontal-line'></div>
                  </div>
                  <div className='gn-sign_in-oauthorization'>
                    <img className='gn-google-image' src={googleLogo} onClick={onGoogleClick} />
                    <img className='gn-phone-image' src={phoneIcon} onClick={onPhoneClick} />
                  </div>
                  <div id='sign-in-button'></div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Footer />
      </div>
    </Translator>
  );
};

export default connect(null, mapDispatchProps)(CreateAccount);
