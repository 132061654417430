import { useEffect } from 'react';

export const useLightMode = (isLightMode) => {
  useEffect(() => {
    const body = document.body; // Target the <body> element
    if (isLightMode) {
      body.classList.add('light-mode');
      body.classList.remove('dark-mode');
    } else {
      body.classList.remove('light-mode');
      body.classList.add('dark-mode');
    }
  }, [isLightMode]);
};
