import React, { useContext, useEffect, useState } from 'react';
import { GuideContext } from '../../../context/GuideContext';
import { getChannelInfo } from 'services/channelInfo';
import { data } from '../../../database/index';
import { connect } from 'react-redux';
import { CloseIcon } from 'components/Guide/svg/general-icons/CloseIcon';
import Categories from 'components/Guide/pages/categories/Categories';
import Subcategories from 'components/Guide/pages/subcategories/Subcategories';
import Items from 'components/Guide/pages/items/Items';
import TypesOrColors from 'components/Guide/pages/types-or-colors/TypesOrColors';
import {
  CATEGORIES,
  CATEGORY,
  ITEM,
  ITEMS,
  SUBCATEGORIES,
  SUBCATEGORY,
  SUCCESS,
  TYPE_OR_COLOR,
  TYPES_OR_COLORS,
} from 'components/Guide/ProductsData';
import { SubmitButton } from 'components/Guide/form-modal/buttons/submit-button/SubmitButton';
import './FormModal.styles.scss';
import { NextButton } from 'components/Guide/form-modal/buttons/next-button/NextButton';
import { BackButton } from 'components/Guide/form-modal/buttons/back-button/BackButton';
import { Success } from 'components/Guide/pages/success/Success';
import { Translator, Translate } from 'react-auto-translate';

const mapStateProps = (state) => ({
  channelInfo: getChannelInfo(state),
});

const FormModal = ({ channelInfo }) => {
  const { isFormModalOpen, formSelections, isFormSubmitted, closeFormModal, currentSection, formOptions, lastSection } =
    useContext(GuideContext);

  const renderCallToAction = () => {
    const selectCategory = formSelections[CATEGORY];
    console.log('selectCategory', selectCategory);
    const sections = {
      [CATEGORIES]: 'What kind of item is it?',
      [SUBCATEGORIES]: "What's the item you're interested in?",
      [ITEMS]: 'Can you specify what the item is?',
      [TYPES_OR_COLORS]: 'Select the type/color of the item:',
    };
    if (selectCategory === 'Vote' && data.Featured.some(channel => channel.number === 464 || channel.number === 465)) {
      sections[SUBCATEGORIES] = 'What song would you like to vote for?';
    }

    return sections[currentSection] || null;
  };

  const renderSection = () => {
    const sections = {
      [CATEGORIES]: <Categories />,
      [SUBCATEGORIES]: <Subcategories />,
      [ITEMS]: <Items />,
      [TYPES_OR_COLORS]: <TypesOrColors />,
      [SUCCESS]: <Success />,
    };

    const isVoteChannel = data.Featured.some(channel => channel.number === 464 || channel.number === 465);

    return (
      <>
        {sections[currentSection]}
        {isVoteChannel && (
          <button className='vote-button'>Vote</button>
        )}
      </>
    );
  };

  const isFormValidated = () => {
    return (
      formSelections[CATEGORY] !== '' &&
      formSelections[SUBCATEGORY] !== '' &&
      !(formSelections[ITEM] === '' && formOptions[ITEMS]?.length > 0) &&
      !(formSelections[TYPE_OR_COLOR] === '' && formOptions[TYPES_OR_COLORS]?.length > 0)
    );
  };


  const disableScrolling = () => {
    const preventScroll = (event) => event.preventDefault();
  
    // Disable all scrolling events
    window.addEventListener('scroll', preventScroll, { passive: false });
    window.addEventListener('wheel', preventScroll, { passive: false });
    window.addEventListener('touchmove', preventScroll, { passive: false });
    
    // Disable keyboard scrolling with arrow keys, spacebar, and other keys
    window.addEventListener(
      'keydown',
      (event) => {
        if ([32, 33, 34, 35, 36, 37, 38, 39, 40].includes(event.keyCode)) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  };
  

  return (
    <>
      <div className='gn-tv-form-modal' data-open={isFormModalOpen}>
      
        <div className='gntv_form-modal__wrapper'>
          <div className='gntv_form-modal__container' data-success={isFormSubmitted}>
            <div className='gntv_form-modal__heading'>
              <span>
                <Translate>Saw something you liked or found interesting?</Translate>
              </span>
              
            </div>

            <span className='gntv_form_modal__subheading'>{renderCallToAction()}</span>

            <div className='gntv_form_modal__item-buttons-container'>{renderSection()}</div>

            {currentSection !== SUCCESS && (
              <div className='gntv-form-modal__navigation_container'>
                {currentSection !== CATEGORIES && (
                  <>
                    <div className='gntv-form-modal__step_buttons'>
                      <BackButton />
                      {(lastSection === currentSection && isFormValidated()) || <NextButton />}
                    </div>
                    <SubmitButton />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormModal;
