import React, { useContext } from 'react';
import { ChannelPopupContext } from '../../context/ChannelPopupContext';
import { connect } from 'react-redux';
import { getVideoId, setVideoInfo } from 'services/channelInfo';
import { Translator, Translate } from 'react-auto-translate';
import { apiKey } from '../../../config.js';
import Modal from 'react-modal';
import gx from '/src/assets/icons/Channel_Logos/gx.png';

const mapStateProps = (state) => ({
  currentVideoId: getVideoId(state),
});

const mapDispatchProps = {
  setVideoInfo,
};

const AgeRestrictionPopup = () => {
  const { isAgeRestrictionPopupOpen, handleCloseAgeRestrictionPopup } = useContext(ChannelPopupContext);
  return (
    <>
      <Translator from='en' to={localStorage.getItem('language')} googleApiKey={apiKey}>
        <Modal
          isOpen={isAgeRestrictionPopupOpen}
          className='gn-tv-channel-age-modal'
          overlayClassName='gn-tv-channel-age-modal-overlay'
          ariaHideApp={false}
        >
          <div className='gn-tv-modal-container'>
            <img src={gx} alt='geaux logo' />
            <div className='gn-tv-modal-title'>
              <span>
                <Translate>This channel is restricted for viewers under 16</Translate>
              </span>
            </div>

            <div className='gn-tv-channel-age-modal-button'>
              <button className='buttonCancel' onClick={handleCloseAgeRestrictionPopup}>
                <span>
                  <Translate>Ok</Translate>
                </span>
              </button>
            </div>
          </div>
        </Modal>
      </Translator>
    </>
  );
};

export default connect(mapStateProps, mapDispatchProps)(AgeRestrictionPopup);
